import React from "react"
import { connect } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { sendLeadForm } from "./../actions/mailing"
import { setAlert } from "./../actions/alert"
import { SWITCH_PROMO } from "./../actions/actionTypes"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

function ContactUs({
  translations,
  lang,
}) {

  const closeContactForm = () => {
    //removePromo();
    navigate(lang === "de" ? "/" : "/en/")
  }

  return (
    <Layout>
      <SEO title={translations.contact_us_title} description={translations.contact_us_meta_description} />
      <div className="subpage lead">
        <div className="lightholder"></div>
        <section>
          <button
            className="contact-closer"
            onClick={closeContactForm}
            title={translations.contact_closer_title}
          >
            <span></span>
            <span></span>
          </button>
          <h1><span className="yellow">{translations.contact_us_headline_lead_form}</span></h1>

          <iframe
            src="https://api.leadconnectorhq.com/widget/form/i3lp7K6ad3Um9ldtSJeP"
            className="simpliform"
            id="inline-i3lp7K6ad3Um9ldtSJeP"
            data-layout="{'id':'INLINE'}"
            data-trigger-type="alwaysShow"
            data-trigger-value=""
            data-activation-type="alwaysActivated"
            data-activation-value=""
            data-deactivation-type="neverDeactivate"
            data-deactivation-value=""
            data-form-name="Landing-Page Automaten Formular"
            data-height="400"
            data-layout-iframe-id="inline-i3lp7K6ad3Um9ldtSJeP"
            data-form-id="i3lp7K6ad3Um9ldtSJeP"
            title="Landing-Page Automaten Formular"
          >
          </iframe>
          <script src="https://link.msgsndr.com/js/form_embed.js"></script>
        </section>
      </div>
    </Layout>
  )
}

ContactUs.propTypes = {
  sendLeadForm: PropTypes.func.isRequired,
  setAlert: PropTypes.func,
}

const mapStateToProps = state => ({
  translations: state.preferences.translations,
  lang: state.preferences.lang,
  serverCall: state.preferences.serverCall,
})

const mapDispatchToProps = dispatch => ({
  removePromo: () => {
    dispatch({ type: SWITCH_PROMO, payload: false })
  },
  sendLeadForm: formData => {
    dispatch(sendLeadForm(formData))
  },
  setAlert: (text, type) => {
    dispatch(setAlert(text, type))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)
